import * as React from 'react';

import { AppPageLayout, AppPageLinks } from 'src/app.consumer/components';
import { Localized, Typography } from 'src/app/components';
import { AppProps, SeoObject } from 'src/app/types';

const seo: SeoObject = {
  title: 'Magazine',
};

// TODO: i18n
const MagazinePage = (props: AppProps) => {
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={'Magazine'}
      title={<Localized dictKey={'staticLocales:pages./magazine.link label'} />}
      {...{ ...props, seo }}
    >
      <Typography variant={'body1'}>Content</Typography>
    </AppPageLayout>
  );
};

export default MagazinePage;
